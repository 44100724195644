// section with MUI cards

import React from 'react';
import { styled } from '@mui/system';

import Box from '@mui/system/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import BoltTwoToneIcon from '@mui/icons-material/BoltTwoTone';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';

const SectionOne = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	padding: theme.spacing(12),
	backgroundColor: theme.palette.common.white,
}));

const CardOne = styled(Box)(({ theme, props }) => ({
	display: 'flex',
	color: theme.palette.text.primary,
	// '& .MuiCardHeader-root': {
	// 	alignItems: 'flex-start',
	// },
	// '& .MuiCardHeader-avatar': {
	// 	marginRight: '36px',
	// 	marginTop: '-32px',
	// },
	'& .MuiAvatar-root': {
		width: '100px',
		height: '100px',
		backgroundColor: '#f9faf6',
		color: theme.palette.primary.main,
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.common.white,
		},
	},
	'& .MuiSvgIcon-root': {
		// width: '3em',
		// height: '3em',
		fontSize: '3rem',
	},
}));

export const item = [
	{
		id: 1,
		title: 'Eyeglass frame repair',
		subheader:
			'We specialise in the repair many styles of metal frame glasses, eyeglasses repair and sunglasses repair.',
		ItemIcon: RemoveRedEyeTwoToneIcon,
	},
	{
		id: 2,
		title: 'Fast turnaround',
		subheader:
			'Our repairs are complete here in Perth not interstate. We complete the repairs within a 3 days.',
		ItemIcon: BoltTwoToneIcon,
	},
	{
		id: 3,
		title: 'High quality laser welding',
		subheader:
			'Laser welding is a very effective method of frame repair. At Repair My Glasses, we make sure that every repair is done to perfection with quick and friendly service.',
		ItemIcon: EmojiEventsTwoToneIcon,
	},
	{
		id: 4,
		title: 'Perth based',
		subheader:
			"We are a Perth based service with a drop off point in Mt Lawley. Simply get in touch to discuss your options and we'll Repair Your Glasses.",
		ItemIcon: LocationOnTwoToneIcon,
	},
];

const SectionFeatureOne = ({ featuresOne }) => (
	<SectionOne className='fOne'>
		<Grid container spacing={4} justifyContent='center' pb={6}>
			<Grid item xs={12} lg={8} textAlign='center'>
				<Typography variant='h2' mb={1}>
					Do you need fast glasses repair?
				</Typography>
				<Typography variant='h4' color='text' pb={1}>
					Our usual turn around time is 3 days...
				</Typography>
				<Typography variant='body1' color='text'>
					however we understand life can be difficult without your glasses. Contact
					us through the chat or leave us a message and we will arrange a faster
					turnaround if at all possible.
				</Typography>
			</Grid>

			<Grid item container spacing={6}>
				{item.map(({ id, title, subheader, ItemIcon }) => (
					<Grid item xs={12} md={6} key={id}>
						<CardOne>
							{/* <CardMedia
									sx={{ width: '30%' }}
									height='50'
									image={logo}
									alt='Paella dish'
								/> */}
							<CardHeader
								avatar={
									<Avatar
										sx={{ mr: { xs: 0, md: 4 }, mt: { xs: 0, md: -4 }, mb: 4 }}
										aria-label='eye icon'
									>
										<ItemIcon />
									</Avatar>
								}
								title={title}
								titleTypographyProps={{ variant: 'h3' }}
								subheader={subheader}
								subheaderTypographyProps={{ variant: 'body1' }}
								sx={{
									flexDirection: { xs: 'column', md: 'row' },
									alignItems: { xs: 'center', md: 'flex-start' },
									textAlign: { xs: 'center', md: 'left' },
								}}
							/>
						</CardOne>
					</Grid>
				))}
			</Grid>
		</Grid>
	</SectionOne>
);

export default SectionFeatureOne;
