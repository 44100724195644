// simple section

import React from 'react';
import { styled } from '@mui/system';
import { StaticImage } from 'gatsby-plugin-image';

import MUILink from '@mui/material/Link';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// import featuredBkgrd from '../img/featured-about.jpg';
import FullWidthImage from './FullWidthImage';
import cbjLogo from '../img/CBJLogoBLOCKWht.png';
import c3Logo from '../img/C3Logo-wht_400.png';

const SectionTwo = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	padding: '78px 0 8px',
	backgroundColor: theme.palette.background,
	position: 'relative',
	alignItems: 'center',
	justifyContent: 'center',
	// backgroundImage: `url('/img/featured-about.jpg')`,
	backgroundPosition: 'center -216px',
	minHeight: '20vh',
	'& .aLogo': {
		width: '100%',
	},
}));

const SectionFeatureThree = ({ item, theme }) => (
	<SectionTwo className='fTwo'>
		<Container maxWidth='lg'>
			<Grid
				container
				// xs={12}
				justifyContent='center'
				// mx='auto'
				// textAlign='center'
				pb={6}
				spacing={10}
			>
				<Grid item xs={11} md={6}>
					<Typography variant='h2'>What’s the process.</Typography>
					<Typography variant='body1' color='text'>
						We offer a{' '}
						<MUILink href='javascript:void(Tawk_API.toggle())'>live chat</MUILink>{' '}
						here on the site for any immediate questions or you can leave us a message
						and we’ll be sure to be in touch.
					</Typography>
					<Typography variant='body1' color='text'>
						Our FAQs on the chat, address a lot of the main questions around getting
						your glasses repaired and the kind of repairs we can and can’t perform.
					</Typography>
					<Typography variant='body1' sx={{ fontWeight: 'bold' }}>
						Check our FAQs to see if we can repair your type of glasses.
					</Typography>
					<Typography variant='body1' color='text'>
						If you have any further questions, reach out via the chat or leave us a
						message if we're not online and we’ll be back in touch.
					</Typography>
					<Typography variant='h3' color='text' pt={2} pb={1}>
						Walk in's and drop offs
					</Typography>
					<Typography variant='body1' color='text'>
						You can also drop your glasses into our partner store,{' '}
						<Typography color='text' sx={{ display: 'inline', fontWeight: 'bold' }}>
							Spectacles of Mount Lawley
						</Typography>{' '}
						located at{' '}
						<Typography color='text' sx={{ display: 'inline', fontWeight: 'bold' }}>
							80 Walcott St, Mount Lawley, Perth.
						</Typography>{' '}
						We’ll let you know when they are repaired and ready to be picked up.
					</Typography>
					<Typography variant='h3' color='text' pt={2} pb={1}>
						Emergency Repairs
					</Typography>
					<Typography variant='body1' color='text'>
						Sometimes, some of us need our glasses ALWAYS!.. and when they are broken
						life becomes very difficult.
					</Typography>{' '}
					<Typography variant='body1' color='text'>
						We understand. Get in touch with us through our chat or leave us a message
						and we will do our best to arrange a faster turn around for these
						emergencies.
					</Typography>
				</Grid>
				<Grid item xs={11} md={6}>
					<Typography variant='h2'>What we can repair</Typography>
					<Typography variant='body1' color='text'>
						Our repairs are completed with a Laser Welder. This does not weld plastic.
						Only metal frame glasses.
					</Typography>
					<Typography variant='body1' color='text'>
						Repairs we can complete include, for example, broken metal arm or metal
						hinge, broken off nose pad, broken metal frame. Most metal frames can be
						repaired and will stand up to regular wear and tear that glasses endure.
					</Typography>
					<Typography variant='h2' pt={4}>
						Repairs we can’t complete.
					</Typography>
					<Typography variant='body1' color='text'>
						Based on our experience with repairing glasses or all shapes and sizes, we
						have found repairs to plastic glasses are not strong enough to warrant the
						repair and usually break again.
					</Typography>
					<Typography variant='body1' color='text'>
						We don’t provide a repair service for plastic glasses as we can’t
						guarantee the work.
					</Typography>
					<Typography variant='body1' color='text'>
						The helpful humans at{' '}
						<Typography color='text' sx={{ display: 'inline', fontWeight: 'bold' }}>
							Spectacles of Mount Lawley
						</Typography>{' '}
						can also suggest your best options for replacement parts or various kinds
						of lens repair or replacement.
					</Typography>
				</Grid>
			</Grid>
		</Container>
	</SectionTwo>
);

export default SectionFeatureThree;
