import React from 'react';
import { Link } from 'gatsby';
import { styled } from '@mui/system';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import BulletTick from './BulletTick';
import ContactButton from './ContactButton';

import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';

import { Typography } from '@mui/material';

const Sidebar = ({ sidebarItems }) => (
	<>
		<Card variant='outlined'>
			<div id='contactUs' />
			<CardHeader
				sx={{
					textAlign: 'center',
					py: 3,
					backgroundColor: 'secondary.main',
				}}
				title='Our glasses repair services including:'
				titleTypographyProps={{ variant: 'h3', color: '#ffffff', p: 0 }}
			/>
			<CardContent>
				{/* <Typography variant='h3'>
					We offer different types of glass repair services including:
				</Typography> */}

				{/* <PreviewCompatibleImage imageInfo={item} /> */}

				<List>
					<ListItem sx={{ alignItems: 'flex-start' }}>
						<BulletTick>
							<CheckCircleTwoToneIcon fontSize='2rem' />
						</BulletTick>
						<ListItemText
							primaryTypographyProps='body1'
							primary='Welding temple hinges, nose pads, arms, eye wire, bridges and other
									parts of metal frames'
						/>
					</ListItem>
					<ListItem sx={{ alignItems: 'flex-start' }}>
						<BulletTick>
							<CheckCircleTwoToneIcon />
						</BulletTick>
						<ListItemText primary='Metal Sunglass repair of broken items.' />
					</ListItem>
					<ListItem sx={{ alignItems: 'flex-start' }}>
						<BulletTick>
							<CheckCircleTwoToneIcon />
						</BulletTick>
						<ListItemText primary='Emergency glasses frame repair, by appointment.' />
					</ListItem>
					<ListItem sx={{ alignItems: 'flex-start' }}>
						<BulletTick sx={{ color: 'error.main' }}>
							<HighlightOffTwoToneIcon />
						</BulletTick>
						<ListItemText primary="We're unable to repair plastic or non metallic frames." />
					</ListItem>
				</List>
			</CardContent>
			<CardActions sx={{ justifyContent: 'center' }}>
				<ContactButton
					variant='contained'
					color='secondary'
					size='lg'
					href='javascript:void(Tawk_API.toggle())'
					rel='noopener noreferrer'
					fullWidth
				>
					Get in Touch
				</ContactButton>
			</CardActions>
		</Card>
		{/* <Box sx={{ mt: 2 }}>
			<Typography variant='h3'>
				For all Appointments
				<br /> please call
			</Typography>
			<Typography variant='h4'>(08) 9279 5666</Typography>
		</Box> */}
	</>
);

export default Sidebar;
