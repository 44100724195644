// simple section

import React from 'react';
import { styled } from '@mui/system';
import { StaticImage } from 'gatsby-plugin-image';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// import featuredBkgrd from '../img/featured-about.jpg';
import FullWidthImage from './FullWidthImage';
import specMtLaw from '../img/best-opticians-perth-logo.png';
import c3Logo from '../img/C3Logo-wht_400.png';

const SectionTwo = styled(Box)(({ theme }) => ({
	display: 'flex',
	backgroundColor: theme.palette.common.white,
	position: 'relative',
	alignItems: 'center',
	justifyContent: 'center',
	minHeight: '20vh',
	':before': {
		content: '" "',
		backgroundImage: `url('/img/smiling-man-check-vision.jpeg')`,
		backgroundSize: 'cover',
		position: 'absolute',
		top: -58,
		right: 0,
		bottom: 0,
		left: 0,
		opacity: '0.7',
		zIndex: 0,
	},
	'& .aLogo': {
		width: '200px',
		width: '230px',
		margin: '0 15% 0 auto',
		display: 'flex',
	},
}));

const CardTwo = styled(Box)(({ theme, props }) => ({
	display: 'flex',
	color: theme.palette.text.primary,
	'& .MuiCardHeader-root': {
		alignItems: 'flex-start',
	},
	'& .MuiCardHeader-avatar': {
		marginRight: '36px',
	},
	'& .MuiAvatar-root': {
		width: '160px',
		height: '160px',
		backgroundColor: '#f9faf6',
		color: theme.palette.primary.main,
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.common.white,
		},
	},
	'& .MuiSvgIcon-root': {
		// width: '3em',
		// height: '3em',
		fontSize: '5rem',
	},
}));

const SectionFeatureFour = ({ item, theme }) => (
	<SectionTwo className='fTwo' p={8}>
		<Grid container spacing={0} justifyContent='right' sx={{ zIndex: 1 }} m={0}>
			<Grid item pr={5} sm={12} md={8} justifyContent='right'>
				<Typography variant='h3' mb={0} sx={{ textAlign: 'right' }}>
					To provide a complete repair and consulting service Repair My Glasses is
					partnering with Perth’s Most Helpful Optician
				</Typography>

				<img src={specMtLaw} alt='CBJ Logo' className='aLogo' />

				<Typography
					variant='body1'
					color='text'
					p={1}
					pt={3}
					sx={{ textAlign: 'right' }}
				>
					Located at 80 Walcott St, Mount Lawley, Perth
				</Typography>
				<Typography variant='body1' color='text' sx={{ textAlign: 'right' }}>
					Monday – Friday 9.30am – 3pm
				</Typography>
			</Grid>
		</Grid>
	</SectionTwo>
);

export default SectionFeatureFour;
