// TS NOTES: a copy of FullWidthImage with added CTAs and buttons.
// created inially for Repair My Glasses landing page

import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import { blueGrey, red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ContactButton from './ContactButton';

import { GatsbyImage } from 'gatsby-plugin-image';
import { Paper } from '@mui/material';

const Section = styled(Box)({
	position: 'relative',
	// minHeight: '80vh',
	display: 'flex',
	alignItems: 'center',
	// marginBottom: '50px',
	justifyContent: 'center',
	backgroundColor: '#f0f1ec',
});

const FrontContent = styled(Box)({
	marginTop: '64px',
	marginBottom: '64px',
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	zIndex: 1,
});

export default function FullWidthImage(props) {
	const {
		height = 400,
		img,
		title,
		subheading,
		imgPosition = 'top center',
	} = props;

	return (
		<Section className='heroWrapper'>
			<FrontContent maxWidth='lg' px={8} className='FntContent'>
				{img?.url ? (
					<img
						src={img}
						objectFit={'cover'}
						objectPosition={imgPosition}
						style={{
							gridArea: '1/1',
							// You can set a maximum height for the image, if you wish.
							height: height,
							width: '100%',
						}}
						// You can optionally force an aspect ratio for the generated image
						aspectratio={3 / 1}
						// This is a presentational image, so the alt should be an empty string
						alt=''
						formats={['auto', 'webp', 'avif']}
					/>
				) : (
					<GatsbyImage
						image={img}
						objectFit={'cover'}
						objectPosition={imgPosition}
						style={{
							gridArea: '1/1',
							// You can set a maximum height for the image, if you wish.
							maxHeight: height,
							position: 'absolute',
							width: '100%',
							top: 0,
							left: 0,
							// height: '100%',
						}}
						// layout='fullWidth'
						// You can optionally force an aspect ratio for the generated image
						// aspectratio={3 / 1}
						// This is a presentational image, so the alt should be an empty string
						alt=''
						formats={['auto', 'webp', 'avif']}
					/>
				)}
				{/* <Paper sx={{ zIndex: 1, p: 4, mb: 4 }}>
					<Typography variant='h3' align='center' sx={{pb:2}} color='secondary'>
						The team at Repair My Glasses are out of the office due to a family matter.
					</Typography>
					<Typography variant='h3' align='center' sx={{pb:2}}  >
						We'll be back on the 14th Feb 2024.
					</Typography>
						<Typography align='center' sx={{pb:0}}>
						Please <Button
								variant='text'
								href='javascript:void(Tawk_API.toggle())'
								rel='noopener noreferrer'
								sx={{color: 'inherit', textDecoration: 'underline', fontSize: 'inherit'}}
							>leave us a message</Button> and we'll get back to you once we're back.
					</Typography>
				</Paper> */}
				<Grid container sx={{ zIndex: '1' }}>
					<Grid item xs={12} sm={12} md={6}>
						<Typography
							variant='h1'
							sx={{
								pb: 4,
								color: 'primary.main',
								'& span': { color: 'secondary.main' },
							}}
						>
							Eyeglass <br /> frame repair. <br />{' '}
							<span>
								High quality <br /> laser welding.
							</span>{' '}
							<br /> Fast turnaround. <br /> <span>Perth based.</span>
						</Typography>
						<Typography>
							Are you looking for a local service to repair your broken glasses frames
							instead of sending them interstate?
						</Typography>
						<Typography variant='h3'>Repair My Glasses can help!</Typography>
						<Stack direction='row' spacing={6} mt={2}>
							{/* <Link to="/services-page" className='link' > */}
							{/* <Button
								variant='contained'
								color='info'
								size='lg'
								rel='noopener noreferrer'
							>
								<FaPlay />
								Our Services
							</Button> */}
							{/* </Link> */}
							<ContactButton
								variant='contained'
								color='secondary'
								size='lg'
								href='javascript:void(Tawk_API.toggle())'
								rel='noopener noreferrer'
							>
								Get in Touch
							</ContactButton>
							<ContactButton
								variant='contained'
								color='info'
								size='lg'
								href='javascript:void(Tawk_API.toggle())'
								rel='noopener noreferrer'
							>
								FAQs
							</ContactButton>
						</Stack>
						{/* <Typography pt={4} variant='h3'>
							or Call Now
						</Typography>
						<Typography>to make a same day appointment</Typography>
						<Typography variant='h3'>to make a same day appointment</Typography> */}
					</Grid>
				</Grid>
			</FrontContent>
		</Section>
	);
}

FullWidthImage.propTypes = {
	img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	height: PropTypes.number,
	subheading: PropTypes.string,
};
