import { styled } from '@mui/system';
import ListItemIcon from '@mui/material/ListItemIcon';

const BulletTick = styled(ListItemIcon)(({ theme }) => ({
	color: theme.palette.success.main,
	paddingTop: theme.spacing(0.5),
	minWidth: 42,
	'& .MuiSvgIcon-root': {
		fontSize: '2rem',
	},
}));

export default BulletTick;
