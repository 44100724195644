// simple section

import React from 'react';
import { styled } from '@mui/system';
import { StaticImage } from 'gatsby-plugin-image';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// import featuredBkgrd from '../img/featured-about.jpg';
import FullWidthImage from './FullWidthImage';
import cbjLogo from '../img/CBJLogoBLOCKWht.png';
import c3Logo from '../img/C3Logo-wht_400.png';

const SectionTwo = styled(Box)(({ theme }) => ({
	display: 'flex',
	padding: '78px 0 8px',
	backgroundColor: theme.palette.common.white,
	position: 'relative',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundImage: `url('/img/featured-about.jpg')`,
	backgroundPosition: 'center -216px',
	minHeight: '20vh',
	'& .aLogo': {
		width: '100%',
	},
}));

const CardTwo = styled(Box)(({ theme, props }) => ({
	display: 'flex',
	color: theme.palette.text.primary,
	'& .MuiCardHeader-root': {
		alignItems: 'flex-start',
	},
	'& .MuiCardHeader-avatar': {
		marginRight: '36px',
	},
	'& .MuiAvatar-root': {
		width: '160px',
		height: '160px',
		backgroundColor: '#f9faf6',
		color: theme.palette.primary.main,
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.common.white,
		},
	},
	'& .MuiSvgIcon-root': {
		// width: '3em',
		// height: '3em',
		fontSize: '5rem',
	},
}));

const SectionFeatureTwo = ({ item, theme }) => (
	<SectionTwo className='fTwo'>
		{/* <FullWidthImage
			img={item.featuredBkgrd}
			title={item.title}
			subheading={subheading}
			height='100%'
		/> */}
		<Container maxWidth='lg'>
			<Grid
				container
				item
				xs={12}
				lg={7}
				// justifyContent='center'
				// mx='auto'
				// textAlign='center'
				pb={6}
			>
				<Typography
					variant='h2'
					mb={0}
					pb={0}
					sx={{ color: 'common.white', textAlign: { xs: 'center', md: 'left' } }}
				>
					Repair My Glasses is a service provide by
				</Typography>
				<Stack
					direction='row'
					justifyContent='flex-start'
					alignItems='center'
					spacing={8}
					sx={{ maxWidth: '80%' }}
				>
					<a title='CBJ' href='#'>
						<img src={cbjLogo} alt='CBJ Logo' className='aLogo' />
					</a>
					<a title='C3 Studio' href='#'>
						<img src={c3Logo} alt='C3 Logo' className='aLogo' />
					</a>
				</Stack>
				{/* <Typography variant='body1' color='text' sx={{ color: 'common.white' }}>
					Call and arrange a time to visit our studio, based in Mt Lawley, and we
					will repair broken frames while you wait.
				</Typography> */}
			</Grid>
		</Container>
	</SectionTwo>
);

export default SectionFeatureTwo;
