// pulled all dynamic content out to initally build out and will add it back on once complete

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';

import { graphql } from 'gatsby';
import MUILink from '@mui/material/Link';
import { getImage } from 'gatsby-plugin-image';

// @material-ui/core components
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import Layout from '../components/Layout';
import SectionFeatureOne from '../components/SectionFeatureOne';
import SectionFeatureTwo from '../components/SectionFeatureTwo';
import SectionFeatureThree from '../components/SectionFeatureThree';
import SectionFeatureFour from '../components/SectionFeatureFour';

import RightHandImage from '../components/RightHandImage';
import ContactSidebar from '../components/ContactSidebar';
import Content, { HTMLContent } from '../components/Content';


const Section = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	zIndex: 1,
});

const MainContent = styled(Box)({
	marginTop: '16px',
	'& .MuiListItem-root': {
		paddingLeft: 0,
	},
});

const FeatureTwo = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	padding: theme.spacing(12),
	backgroundColor: theme.palette.background,
}));


// eslint-disable-next-line
export const RMGLandingSTATIC = ({
	image,
	title,
	heading,
	subheading,
	mainpitch,
	description,
	intro,
	content,
	contentComponent,
}) => {
	const heroImage = getImage(image) || image;
	const PageContent = contentComponent || Content;

	return (
		<>
			<RightHandImage
				img={heroImage}
				// title={title}
				// subheading={subheading}
				height='100%'
				imgPosition='center center'
			/>
			<Section sx={{ bgcolor: 'common.white' }}>
				<MainContent maxWidth='lg' className='mainContent' px={4}>
					{/* <PageContent className='content' content={content} /> */}
					<Grid
						container
						spacing={2}
						sx={{ justifyContent: 'space-between' }}
						py={8}
					>
						<Grid item xs={12} sm={12} md={7} className='section'>
							<Typography variant='h2' className='title'>
								Why spend money on buying a new frame when you can get your eyeglasses
								repaired?
							</Typography>
							<Typography variant='body1'>
								At 'Repair My Glasses', we offer high quality eyeglass repair service to
								make your glasses look as good as new.
							</Typography>
							<Typography variant='body1'>
								Our technicians are trained in using high quality laser welding
								techniques for fixing eyeglasses and provide fast 3 day turnaround for
								the Perth metro and regional WA.
							</Typography>
							<Typography variant='body1'>
								'Repair My Glasses' can repair a large variety of accidental or wear
								damage to glasses frames.
							</Typography>
							<Typography variant='body1'>
								<MUILink href='javascript:void(Tawk_API.toggle())'>
									Chat with us now
								</MUILink>{' '}
								to see if your glasses can be repaired.
							</Typography>
							<Typography sx={{ pt: 2 }} variant='h3'>
								How our Service works.
							</Typography>
							<Typography variant='body1'>
								Repair My Glasses is an online service with a standard 3 day turn around
								and multiple options for getting your glasses to us for repair.
							</Typography>
							<Typography variant='body1'>
								With a convenient Mt Lawley drop off and pick up point and a daily
								cleared postal box getting your glasses repaired is quick, easy and
								professional.
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<ContactSidebar
								// sidebarItems={intro.blurbs}
								className='contactSidebar1'
							/>
						</Grid>
					</Grid>
				</MainContent>
			</Section>
			<SectionFeatureFour />

			<SectionFeatureThree />
			<SectionFeatureOne />
			<SectionFeatureTwo />
		</>
	);
};

RMGLandingSTATIC.propTypes = {
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	heading: PropTypes.string,
	subheading: PropTypes.string,
	mainpitch: PropTypes.object,
	description: PropTypes.string,
	intro: PropTypes.shape({
		blurbs: PropTypes.array,
	}),
};

const RMGLanding = ({ data }) => {
	const { frontmatter } = data.markdownRemark;
	const { markdownRemark: post } = data;

	return (
		<Layout>
			<RMGLandingSTATIC
				image={frontmatter.image}
				title={frontmatter.title}
				heading={frontmatter.heading}
				subheading={frontmatter.subheading}
				mainpitch={frontmatter.mainpitch}
				description={frontmatter.description}
				intro={frontmatter.intro}
				contentComponent={HTMLContent}
				content={post.html}
			/>
		</Layout>
	);
};

RMGLanding.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default RMGLanding;

export const pageQuery = graphql`
	query RMGLandingSTATIC {
		markdownRemark(frontmatter: { templateKey: { eq: "RMG-landing" } }) {
			html
			frontmatter {
				title
				image {
					childImageSharp {
						gatsbyImageData(quality: 100, layout: FULL_WIDTH)
					}
				}
				heading
				subheading
				mainpitch {
					title
					description
				}
				description
				intro {
					blurbs {
						text
						heading
					}
					heading
					description
				}
			}
		}
	}
`;
